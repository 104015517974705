import React from 'react';
import { Text } from 'react-konva';
import "../../Styles/style.css"
type officeProps = {
  office: string
}

export const Office = ({ office }: officeProps) => {
  return (
    <Text
      x={176.69}
      y={33.17}
      text={office}
      fontSize={11.39}
      fontFamily="Work Sans"
      fontVariant='400'
      fill="white"
    />
  );
}