import { Stage, Layer} from 'react-konva';

import { Background } from '../Components/Background';
import { Logotipo } from '../Components/Logotipo';

import Konva from "konva"
import { Name } from '../Components/Text/Name';
import { Phone } from '../Components/Text/Phone';
import { Email } from '../Components/Text/Email';
import { Office } from '../Components/Text/Office';
import { MutableRefObject, ReactEventHandler } from 'react';

type dadosProps ={
  name: string,
  office: string,
  email: string,
  phone: string,
  stageRef: MutableRefObject<Konva.Stage>
}
export const Viewer = ({ name, office, email, phone, stageRef }: dadosProps) => {
    return (
        <Stage
            width={430}
            height={100}
            ref={stageRef}
        >
            <Layer>
                <Background />
                <Logotipo />
                
            </Layer>
            <Layer>
                <Name name={name} />
                <Office office={office} />
                <Email  email={email} />
                <Phone phone={phone} />
            </Layer>
        </Stage>
    )
}