import React from 'react';
import { Text } from 'react-konva';
import "../../Styles/style.css"
type emailProps = {
  email: string
}

export const Email = ({ email }: emailProps) => {
  return (
    <Text id='fontEmail'
      x={176.69}
      y={74.9}
      text={email}
      fontSize={11.3879}
      fontFamily="Work Sans"
      fontWeight='400'
      fill="white"
    />
  );
}