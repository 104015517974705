import React from 'react';
import { Text } from 'react-konva';
import "../../Styles/style.css"
type nameProps = {
  name: string
}

export const Name = ({name}:nameProps) => {
  return (
    <Text id='nameFont'
      x={176.69}
      y={13.17}
      text={name}
      fontSize={17}
      fontFamily="Work Sans"
      fontVariant='600'
      fill=" #FFFFFF"
    />
  );
}