import useImage from 'use-image';
import { Image } from 'react-konva';

import Logo from "../Assets/logo.svg"

export const Logotipo = () => {

    const [image] = useImage(Logo, 'anonymous');

    return (
        <Image
            x={14.23}
            y={59.58}
            width={192}
            height={48.3}
            image={image}
            scaleX={0.6}
            scaleY={0.6}    
        />
    )
}