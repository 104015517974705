import useImage from 'use-image';
import { Image } from 'react-konva';

import Fundo from "../Assets/fundoEmail.jpg"

export const Background = () => {

    const [image] = useImage(Fundo, 'anonymous');

    return (
        <Image
            x={0}
            y={0}
            width={430}
            height={100}
            image={image}
        />
    )
}