import { yupResolver } from "@hookform/resolvers/yup";
import { SetStateAction, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import * as yup from "yup";
import logoForm from "../Assets/logoForm.svg";
import { Viewer } from "../Components/Viewer";
import "../Styles/style.css";

interface IFormInputs {
  name: string;
  office: string;
  email: string;
  phone: string;
}

const regex = /[a-zA-Z\u00C0-\u00FF ]+/i;
const SignupSchema = yup
  .object({
    name: yup
      .string()
      .required("Campo Obrigatório")
      .max(25, "Máximo de 25 caracteres")
      .min(2, "Mínimo 2 caracteres"),

    email: yup
      .string()
      .required("Campo Obrigatório")
      .matches(regex, "Somente nome e sobrenome"),

   
    phone: yup.string().test(
      "Telefone Inválido",
      "Telefone Inválido",

      function (phoneValidation) {
        const valuePhone = phoneValidation
          ?.split("")
          .filter((value) => !isNaN(parseInt(value)))
          .join("");

        return (
          valuePhone?.length === 0 ||
          (valuePhone || "").length === 11 ||
          (valuePhone || "").length === 12 ||
          (valuePhone || "").length === 13 ||
          (valuePhone || "").length === 15
        );
      }
    ),
  })
  .required();

function Form() {
  const stageRef = useRef<any>(null);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(SignupSchema),
  });
  const watchAllFields = watch();
  const watchFields = watch(["name", "office", "email", "phone"]);

  const download = () => {
    const stage = stageRef.current.toDataURL();
    const link = document.createElement("a");

    var timestamp = new Date().getTime();

    link.download = `pumpkin-signature-${timestamp}.jpg`;
    link.href = stage;
    link.click();
  };

  const [office, setIsOffice] = useState<string>("");
  function selectionOffice(event: any) {
    setIsOffice(event.target.value);
  }
  const officeData = [
    {
      id: 0,
      name: "Selecionar cargo...",
      value: "",
    },
    {
      id: 1,
      name: "CEO",
      value: "CEO",
    },
    {
      id: 2,
      name: "CFO",
      value: "CFO",
    },
    {
      id: 3,
      name: "COO",
      value: "COO",
    },
    {
      id: 4,
      name: "CTO",
      value: "CTO",
    },
    {
      id: 5,
      name: "Data Scientist",
      value: "Data Scientist",
    },
    {
      id: 6,
      name: "Finance Specialist",
      value: "Finance Specialist",
    },
    {
      id: 7,
      name: "Head of People",
      value: "Head of People",
    },
    {
      id: 8,
      name: "Head of Operations",
      value: "Head of Operations",
    },

    {
      id: 9,
      name: "Head of Technology",
      value: "Head of Technology",
    },
    {
      id: 10,
      name: "Product Manager",
      value: "Product Manager",
    },
    {
      id: 11,
      name: "Sales Engineer",
      value: "Sales Engineer",
    },
    {
      id: 12,
      name: "Software Engineer",
      value: "Software Engineer",
    },
    {
      id: 13,
      name: "Tech Lead",
      value: "Tech Lead",
    },
    {
      id: 14,
      name: "Test Engineer",
      value: "Test Engineer",
    },
    {
      id: 15,
      name: "Trainee",
      value: "Trainee",
    },
    {
      id: 16,
      name: "UX Engineer",
      value: "UX Engineer",
    },
  ];

  const handleOnChange = (
    value: any,
    data: any,
    event: any,
    formattedValue: SetStateAction<string>
  ) => {
    setValue("phone", value, { shouldDirty: true, shouldValidate: true });
    setIsPhone(formattedValue);
  };

  const [phone, setIsPhone] = useState<string>("");
  const novo = watchAllFields.email;
  const email = (novo === undefined ? "" : novo) + "@pumpkintech.com.br";

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-4 p-0">
          <div className="menu">
            <div className="logo">
              <a href="https://www.pumpkintech.com.br/">
                <img src={logoForm} alt="logo" />{" "}
              </a>
              <p>Signature Generator</p>
            </div>
            <form onSubmit={handleSubmit(download)}>
              <div className="inputs">
                <div className="form-floating mb-3">
                  <input
                    autoComplete="off"
                    {...register("name")}
                    type="text"
                    className="form-control"
                    id="floatingNome"
                    placeholder="Fulano Ciclano"
                    maxLength={25}
                  />

                  <label htmlFor="floatingNome">Nome*</label>
                </div>
                <span id="erro">
                  {errors.name && <p>{errors.name.message}</p>}
                </span>

                <div className="form-floating mb-3">
                  <select
                    required
                    className="form-select form-select-lg mb-3 pd"
                    aria-label=".form-select-lg example"
                   
                    name=""
                    onChange={selectionOffice}
                    id=""
                  >
                    {officeData.map((item) => (
                      <>
                        <option value={item.value}>{item.name}</option>
                      </>
                    ))}
                  </select>
                </div>
                

                <div className="form-floating mb-3 ">
                  <input
                    type="text"
                    autoComplete="off"
                    className="form-control border"
                    placeholder="Recipient's username"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    maxLength={20}
                    {...register("email")}
                  />
                  <span className="input-group-text radius" id="basic-addon2">
                    @pumpkintech.com.br
                  </span>

                  <label htmlFor="floatingEmail">E-mail*</label>
                </div>
                <span id="erro">
                  {errors.email && <p>{errors.email.message}</p>}
                </span>
                <div className="form-floating mb-3">
                  <PhoneInput
                    {...register("phone")}
                    country={"br"}
                    masks={{ br: "(..) .....-...." }}
                    onChange={handleOnChange}
                    inputClass={"form-control !important"}
                    placeholder={"(XX) XXXXX-XXXX"}
                  />
                </div>
                <span id="erro">
                  {errors.phone && <p>{errors.phone.message}</p>}
                </span>
                <button
                  className="btn btn-pumpkin btn-block btn-lg w-100"
                  type="submit"
                >
                  Salvar Imagem
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="col-md-8 p-0">
          <main>
            <div className="bg-white">
              <Viewer
                stageRef={stageRef}
                name={watchAllFields.name}
                office={office}
                email={email}
                phone={phone}
              />
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}

export default Form;
