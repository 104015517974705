import React from 'react';
import { Text } from 'react-konva';
import "../../Styles/style.css"
type phoneProps ={
  phone: string
  
}
export const Phone = ({ phone }: phoneProps) => {
  return (
    <Text
      x={176.69}
      y={60.83}
      text={phone}
      fontSize={11.3879}
      fontStyle='normal'
      fontFamily="Work Sans"
      fontVariant='400'
      fill="white"
    />
  );
}